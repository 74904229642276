.App {
  text-align: center;
}

@keyframes lockedOut {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateZ(1000px);
  }
  75% {
    opacity: 1;
  }
}

.custom-loading {
  /* opacity: 1; */
  animation: lockedOut 0.1s ease-in;
}
